import React from 'react'
import Grid from '@mui/material/Grid'
import Logo from '../../molecules/Logo'
import Container from '@mui/material/Container'

import styles from './styles.module.scss'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'

const Header = ({ title, button = { label: 'connect wallet', clickHandler: null } }) => {
  const theme = useTheme()
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const isXs = useMediaQuery(theme.breakpoints.down('md'))

  const _renderButton = () => (
    <Grid item xs={12} md={6} xl={3} display='flex' justifyContent='flex-end' alignItems='center'>
      <Button variant={'contained'} onClick={button.clickHandler} fullWidth={isXs}>{button.label}</Button>
    </Grid>
  )

  const _renderTitle = () => (
    <Grid
      flexGrow={1}
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='h3' className={styles.title}>{title}</Typography>
    </Grid>
  )

  return (
    <Container maxWidth={false} className={styles.root}>
      <Grid container pr={isXs ? 0 : 3} rowGap={4} overflow={'hidden'}>
        <Grid item xs={12} md={6} xl={3} alignItems='center' display={isXs && 'flex'} justifyContent={isXs && 'center'}>
          <Logo />
        </Grid>
        {isXl ? (
          <>
            {_renderTitle()}
            {_renderButton()}
          </>
        ) : (
          <>
            {_renderButton()}
            {_renderTitle()}
          </>
        )}
      </Grid>
    </Container>
  )
}

export default Header
