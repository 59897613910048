const zodiacList = [
    {
        value: 'aries', label: 'Aries',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/aries.png'),
            alt: 'aries zodiac symbol'
        }
    },
    {
        value: 'virgo', label: 'Virgo',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/virgo.png'),
            alt: 'virgo zodiac symbol'
        }
    },
    {
        value: 'taurus', label: 'Taurus',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/taurus.png'),
            alt: 'taurus zodiac symbol'
        }
    },
    {
        value: 'libra', label: 'Libra',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/libra.png'),
            alt: 'libra zodiac symbol'
        }
    },
    {
        value: 'leo', label: 'Leo',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/leo.png'),
            alt: 'leo zodiac symbol'
        }
    },
    {
        value: 'cancer', label: 'Cancer',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/cancer.png'),
            alt: 'cancer zodiac symbol'
        }
    },
    {
        value: 'aquarius', label: 'Aquarius',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/aquarius.png'),
            alt: 'aquarius zodiac symbol'
        }
    },
    {
        value: 'pisces', label: 'Pisces',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/pisces.png'),
            alt: 'pisces zodiac symbol'
        }
    },
    {
        value: 'sagittarius', label: 'Sagittarius',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/sagittarius.png'),
            alt: 'sagittarius zodiac symbol'
        }
    },
    {
        value: 'gemini', label: 'Gemini',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/gemini.png'),
            alt: 'gemini zodiac symbol'
        }
    },
    {
        value: 'capricorn', label: 'Capricorn',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/capricorn.png'),
            alt: 'capricorn zodiac symbol'
        }
    },
    {
        value: 'scorpio', label: 'Scorpio',
        image: {
            src: require('../assets/images/zodiacSigns/glowing/scorpio.png'),
            alt: 'scorpio zodiac symbol'
        }
    }
]

export default zodiacList

