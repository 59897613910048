import React from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { Button, Grid, Typography } from "@mui/material";
import styles from "./styles.module.scss";

import mockZodiacs from "../../../constants/mockZodiacs";
import zodiacList from "../../../constants/zodiacList";
import ZodiacsList from "../ZodiacsList";
import ZodiacSymbols from "../ZodiacSymbols";
import CustomLabel from "../../atoms/CustomLabel";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "rgba(0, 0, 0, 0.5)";
  -webkit-tap-highlight-color: transparent;
`;

const bgModal = {
  height: "90%",
  background: "rgba(0, 0, 0, 1)",
  border: "8px solid #FFFFFF",
  borderRadius: "32px",
  boxShadow: "0px 0px 64px #FF0073",
  p: 2,
  px: 0,
  pb: 3,
  pl: 5,
  pr: 5,
  m: 6,
};

const ownedZodiacsContainer = {
  overflow: "auto",
  maxHeight: "100%",
  pl: 4,
};

const contentContainer = {
  height: "75%",
  maxHeight: "100%",
};

const ownedContainer = {
  height: "90%",
  overflow: "auto",
  p: 2,
};

export default function ZodiacBackgroundModal(props) {
  const handleClose = () => props.setOpen(false);

  return (
    <StyledModal
      aria-labelledby="zodiac-select-title"
      aria-describedby="zodiac-list"
      open={props.open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
    >
      <Grid container item xs={6} sx={bgModal} display="flex">
        <Grid item xs={12} className={styles.title}>
          <Typography variant="h3">Backgrounds</Typography>
          <CustomLabel>Burn one Zodiac</CustomLabel>
        </Grid>
        <Grid container item xs={12} sx={contentContainer}>
          <Grid container item xs={12} sx={ownedZodiacsContainer}>
            <Grid item xs={12} sx={ownedContainer}>
              <ZodiacsList
                key={props.selectedZodiacId}
                zodiacs={props.zodiacs}
                setSelectedZodiacId={props.setSelectedZodiacId}
                selectedZodiacId={props.selectedZodiacId}
              />
            </Grid>
          </Grid>
        </Grid>
        {props.selectedZodiacId && (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={() => {
                handleClose();
                props.unlockBackgrounds();
              }}
            >
              Burn Zodiac to Unlock Backgrounds
            </Button>
          </Grid>
        )}
      </Grid>
    </StyledModal>
  );
}
