import React from "react";
import Container from "@mui/material/Container";

import Header from "../../organisms/Header";
import BelongingsPanel from "../../organisms/BelongingsPanel";

import styles from "./styles.module.scss";

const MainLayout = ({ title, button, children, mainStyles }) => {
  return (
    <>
      <Header title={title} button={button} />
      <Container
        component={"main"}
        className={[styles.content, mainStyles].join(" ")}
        maxWidth={"xl"}
      >
        {children}
        {/* <BelongingsPanel /> */}
      </Container>
    </>
  );
};

export default MainLayout;
