export const mockCapsuleOptions = {
  profilePic: [
    { value: "Capsule", label: "Capsule" },
    { value: "Portrait (Original)", label: "Portrait (Original)" },
    { value: "Animated (Original)", label: "Animated (Original)" },
    { value: "Portrait", label: "Portrait" },
    { value: "Animated", label: "Animated" },
  ],
  defaultBackgrounds: [
    { value: "Day", label: "Day" },
    { value: "Simple 1", label: "Simple 1" },
  ],
  defaultBackgroundsMythic: [
    { value: "Day", label: "Day" },
    { value: "Simple 1", label: "Simple 1" },
    { value: "Mythic", label: "Mythic" },
  ],
  unlockedBackgrounds: [
    { value: "Day", label: "Day" },
    { value: "Night", label: "Night" },
    { value: "Simple 1", label: "Simple 1" },
    { value: "Simple 2", label: "Simple 2" },
    { value: "Simple 3", label: "Simple 3" },
    { value: "Simple 4", label: "Simple 4" },
  ],
  unlockedBackgroundsMythic: [
    { value: "Day", label: "Day" },
    { value: "Night", label: "Night" },
    { value: "Simple 1", label: "Simple 1" },
    { value: "Simple 2", label: "Simple 2" },
    { value: "Simple 3", label: "Simple 3" },
    { value: "Simple 4", label: "Simple 4" },
    { value: "Mythic", label: "Mythic" },
  ],
  animated: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
};
