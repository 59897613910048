import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import styles from './styles.module.scss'

const CustomLabel = ({ mb = 4, label, children }) => {
  return (
    <Grid mb={mb} container direction='column'>
      <Grid>
        <Typography variant={'body1'} mb={1.5} className={styles.label}>{label}</Typography>
      </Grid>
      <Grid>
        {children}
      </Grid>
    </Grid>
  )
}

export default CustomLabel
