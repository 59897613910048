import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";

import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import Zodiacs from "../../../constants/zodiacs";

const ZodiacCard = ({
  id,
  title,
  metadata,
  token_uri,
  setSelectedZodiacId,
  setSelectedZodiacList,
  isSelected,
  variant,
  setTwelveZodiacs,
  twelveZodiacs,
}) => {
  // Set initial state values for revert reference
  // when user deselects
  const initialSelectedState = "select";
  const initialStyleState = styles.overlay;
  const [style, setStyle] = useState(
    isSelected ? styles.selected : initialStyleState
  );
  const [selected, setSelected] = useState(isSelected);

  // revert to initial state when user deselects
  const changeStyle = () => {
    if (style != initialStyleState) {
      setStyle(initialStyleState);
    } else {
      setStyle(styles.selected);
    }
  };

  const changeSelected = () => {
    if (selected) {
      setSelectedZodiacId(null);

      if (setTwelveZodiacs) {
        setTwelveZodiacs(variant, null);
      }
    } else {
      setSelectedZodiacId(id);

      if (setTwelveZodiacs) {
        setTwelveZodiacs(variant, id);
      }
    }
    changeStyle();
  };

  const image = Zodiacs[variant];

  return (
    <Grid item xs={12} sm={4} md={4} xl={3} key={id} component={"li"}>
      <Grid container flexDirection="column">
        <Grid className={styles.card} item xs={12} mb={2}>
          <img alt={image.alt} src={image.src} key={id} />
          <Grid className={style}>
            <Button onClick={changeSelected} size={"small"} variant="contained">
              select
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className={styles.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className={styles.title}>
            {variant}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

ZodiacCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default ZodiacCard;
