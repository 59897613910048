import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import styles from "./styles.module.scss";
import { Typography } from "@mui/material";

const ZodiacSymbol = ({
  value,
  image,
  label,
  isFiltered,
  setFilteredZodiac,
  filledZodiacID,
}) => {
  const initialStyleState = styles.overlay;
  const [style, setStyle] = useState(
    isFiltered ? styles.selected : initialStyleState
  );

  const [filtered, setFiltered] = useState(isFiltered);

  const changeStyle = () => {
    if (style != initialStyleState) {
      setStyle(initialStyleState);
    } else {
      setStyle(styles.selected);
    }
  };

  const filter = () => {
    if (filtered) {
      setFilteredZodiac(null);
    } else {
      setFilteredZodiac(label);
    }
    changeStyle();
  };

  return (
    <Grid item xs={12} sm={3} md={3} xl={3} component={"li"}>
      <Grid container flexDirection="column">
        <Grid container item justifyContent="center" alignItems="center">
          <Grid
            container
            item
            className={styles.card}
            m={2}
            justifyContent="center"
            alignItems="center"
          >
            <img
              alt={image.alt}
              src={filledZodiacID ? image.glowing : image.standard}
            />

            <Grid className={style}>
              <Button onClick={filter} size={"small"} variant="contained">
                filter
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" className={styles.title}>
            {label}
          </Typography>
          <Typography variant="body1" className={styles.title}>
            {filledZodiacID ? filledZodiacID.padStart(5, "0") : " "}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ZodiacSymbol;
