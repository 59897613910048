import React from 'react'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'

const CustomLink = ({ className, ...props }) => {
  const classes = [className, styles.root].join(' ')
  return (
    <Link {...props} className={classes} />
  )
}

export default CustomLink
