import * as React from 'react'

import Router from './routes/Router'

import './theme/global.css'
import { useMemo, useState } from 'react'
import { BelongingsContext } from './context/belongingsContext'

export default function App() {
  const [data, setData] = useState([
    {
      id: 1,
      name: 'health',
      counter: 2
    },
    {
      id: 2,
      name: 'mana',
      counter: 5
    }
  ])

  const onUse = id => {
    setData(data.map(item => {
      if (item.id === id && item.counter > 0) {
        return {
          ...item,
          counter: item.counter - 1
        }
      }
      return item
    }))
  }

  const value = useMemo(
    () => ({ data, onUse }),
    [data]
  );

  return (
    <BelongingsContext.Provider value={value}>
      <Router/>
    </BelongingsContext.Provider>
  )
}

