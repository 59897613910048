import React from 'react'
import Header from '../../organisms/Header'
import Container from '@mui/material/Container'

import styles from './styles.module.scss'

const AuthLayout = ({ title, button, children, mainStyles }) => {
  return (
    <>
      <Header title={title} button={button} />
      <Container
        component={'main'}
        className={[styles.content, mainStyles].join(' ')}
        maxWidth={'xl'}
      >
        {children}
      </Container>
    </>
  )
}

export default AuthLayout
