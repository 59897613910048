import React from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { Button, Grid, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import CustomLabel from "../../atoms/CustomLabel";
import Ripple from "./ripple.svg";
import { mapToStyles } from "@popperjs/core/lib/modifiers/computeStyles";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "rgba(0, 0, 0, 0.5)";
  -webkit-tap-highlight-color: transparent;
`;

const bgModal = {
  height: "25%",
  background: "rgba(0, 0, 0, 1)",
  border: "8px solid #FFFFFF",
  borderRadius: "32px",
  boxShadow: "0px 0px 64px #FF0073",
  p: 2,
  px: 0,
  pb: 3,
  pl: 5,
  pr: 5,
  m: 6,
};

const GenericModal = ({ open, modalTitle, message, handleClose }) => {
  /**
   * modal use:
   *  const [open, setOpen] = useState(false);
   *  const handleOpen = () => setOpen(true);
   *  const handleClose = () => setOpen(false);
   */

  return (
    <StyledModal
      aria-labelledby="zodiac-select-title"
      aria-describedby="zodiac-list"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
    >
      <Grid container item xs={5} sx={bgModal} display="flex">
        <Grid item xs={12} className={styles.title}>
          {/* <Typography variant="h4">
                        {modalTitle || 'Processing request'}
                    </Typography> */}
          <CustomLabel>{message || "Processing your request..."}</CustomLabel>
          <img className={styles.ripple} src={Ripple} alt="Ripple" />
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default GenericModal;
