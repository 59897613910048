import React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

import ZodiacSymbol from "../ZodiacSymbol";
import styles from "./styles.module.scss";

// Grid of Zodiac Signs
const ZodiacSymbols = ({
  zodiacSymbols = [],
  setFilteredZodiac,
  filteredZodiac,
  twelveZodiacs,
}) => {
  return (
    <Grid container component={"ul"} className={styles.root}>
      {zodiacSymbols.map(({ value, image, label }) => {
        return (
          <ZodiacSymbol
            key={value}
            id={value}
            image={image}
            label={label}
            filledZodiacID={twelveZodiacs[label]}
            isFiltered={filteredZodiac === label}
            setFilteredZodiac={setFilteredZodiac}
          />
        );
      })}
    </Grid>
  );
};

ZodiacSymbols.propTypes = {
  zodiacSymbols: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ZodiacSymbols;
