const zodiacs = {
  Aries: {
    src: require("../assets/images/zodiacs/aries.png").default,
    alt: "aries zodiac symbol",
  },
  Taurus: {
    src: require("../assets/images/zodiacs/taurus.png").default,
    alt: "taurus zodiac symbol",
  },
  Gemini: {
    src: require("../assets/images/zodiacs/gemini.png").default,
    alt: "gemini zodiac symbol",
  },
  Cancer: {
    src: require("../assets/images/zodiacs/cancer.png").default,
    alt: "cancer zodiac symbol",
  },
  Leo: {
    src: require("../assets/images/zodiacs/leo.png").default,
    alt: "leo zodiac symbol",
  },
  Virgo: {
    src: require("../assets/images/zodiacs/virgo.png").default,
    alt: "virgo zodiac symbol",
  },
  Libra: {
    src: require("../assets/images/zodiacs/libra.png").default,
    alt: "libra zodiac symbol",
  },
  Scorpio: {
    src: require("../assets/images/zodiacs/scorpio.png").default,
    alt: "scorpio zodiac symbol",
  },
  Sagittarius: {
    src: require("../assets/images/zodiacs/sagittarius.png").default,
    alt: "sagittarius zodiac symbol",
  },
  Capricorn: {
    src: require("../assets/images/zodiacs/capricorn.png").default,
    alt: "capricorn zodiac symbol",
  },
  Aquarius: {
    src: require("../assets/images/zodiacs/aquarius.png").default,
    alt: "aquarius zodiac symbol",
  },
  Pisces: {
    src: require("../assets/images/zodiacs/pisces.png").default,
    alt: "pisces zodiac symbol",
  },
};

export default zodiacs;
