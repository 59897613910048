import React from "react";
import Typography from "@mui/material/Typography";

import { ROUTES } from "../../../routes/routes";
import CustomLink from "../../atoms/CustomLink";

import styles from "./styles.module.scss";
import capsule from "../../../assets/images/capsule.png";

const Logo = () => {
  return (
    <CustomLink
      to={ROUTES.Home}
      href={ROUTES.Home}
      className={styles.logoContainer}
    >
      <Typography variant={"h5"} className="text-uppercase">
        capsule house
      </Typography>
      <img src={capsule} alt="capsule" />
    </CustomLink>
  );
};

export default Logo;
