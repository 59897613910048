import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import styles from "./styles.module.scss";

const StatsParser = ({ stats }) => {
  return (
    <Grid>
      {stats.map((attribute, index) => (
        <Grid key={index} container mb={1.5}>
          <Grid item>
            <Typography variant={"body1"} className={styles.key}>
              {attribute.trait_type}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={"body1"} key={index}>
              {attribute.value}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

StatsParser.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default StatsParser;
