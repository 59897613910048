import React, { useState, useEffect } from "react";
import { InputAdornment, OutlinedInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import MainLayout from "../../components/templates/MainLayout";
import mockCapsules from "../../constants/mockCapsules";
import CapsulesList from "../../components/organisms/CapsulesList";
import Login from "../Login/";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search.svg";
import { ethers, utils } from "ethers";
import axios from "axios";
import {
  MORALIS_KEY,
  BASE_IMAGE_URI,
  BASE_MORALIS_URI,
  OWNED_CAPSULES_ADDRESS,
} from "../../config";

const Home = () => {
  const [search, setSearch] = useState("");
  const [isLoggedIntoMetamask, setIsLoggedIntoMetamask] = useState(false);
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState(null);
  const [didLoad, setDidLoad] = React.useState(false);
  const [didFetch, setDidFetch] = useState(false);
  const [capsules, setCapsules] = useState([]);

  const updateSearch = (e) => {
    setSearch(e.target.value);
  };

  const refreshData = async () => {
    try {
      if (typeof web3 !== "undefined") {
        console.log("web3 is enabled 34");
        await initWallet();
        if (web3.currentProvider.isMetaMask === true) {
          console.log("MetaMask is active");
        } else {
          console.log("MetaMask is not available");
        }
      } else {
        console.log("web3 is not found");
      }
      const allCapsulesResponse = await axios.get("/api/getAllCapsulesMetadata", {});
      console.log(45, allCapsulesResponse);

      if (address) {
        const moralisKey = MORALIS_KEY;
        const ownedCapsulesResponse = await axios.get(
          `${BASE_MORALIS_URI}${address}${OWNED_CAPSULES_ADDRESS}`,
          {
            headers: {
              "X-API-Key": moralisKey,
            },
          }
        );

        const tokenIds = ownedCapsulesResponse.data.result.map((capsule) => {
          return capsule.token_id;
        });

        const capsulesResponse = await axios.get("/api/getCapsuleMetadata", {
          params: {
            tokenIds,
          },
        });

        const results = ownedCapsulesResponse.data.result.map((capsule) => {
          capsule.title = capsule.token_id.padStart(5, "0");
          capsule.image = {
            src: capsulesResponse.data
              .find((c) => {
                return c.name === capsule.title;
              })
              .image.replace("gateway.pinata", "tunes.mypinata"),
          };
          return capsule;
        });

        setCapsules(
          results.sort((a, b) =>
            parseInt(a.token_id) > parseInt(b.token_id) ? 1 : -1
          )
        );
      }

      setDidFetch(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!didLoad || !isLoggedIntoMetamask) {
      setDidLoad(true);
    }
  }, [didLoad, isLoggedIntoMetamask]);

  useEffect(() => {
    refreshData();
  }, [address]);

  const capsulesData = search
    ? capsules.filter((item) => item.title.includes(search))
    : capsules;

  const initWallet = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const accounts = await provider.listAccounts();

    window.ethereum.on("accountsChanged", function (accounts) {
      location.reload();
    });

    window.ethereum.on("chainChanged", (chainId) => {
      location.reload();
    });

    if (accounts.length > 0) {
      const address = await provider.getSigner(0).getAddress();

      setIsLoggedIntoMetamask(true);
      setProvider(provider);
      setAddress(address);
    }
  };

  const connectWallet = async () => {
    console.log("test");
    await window.ethereum.enable();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const address = await provider.getSigner(0).getAddress();

    setIsLoggedIntoMetamask(true);
    setProvider(provider);
    setAddress(address);

    return address;
  };

  if (!isLoggedIntoMetamask) {
    return <Login connectWallet={connectWallet} />;
  }

  console.log(capsules);

  return (
    <MainLayout
      title="your capsules"
      button={{ label: address ? address.substring(0, 11) + "..." : "" }}
    >
      <Grid container mb={6} pt={6.5} display="flex" justifyContent="center">
        <Grid item xs={7.5}>
          <OutlinedInput
            variant={"outlined"}
            placeholder={"search items"}
            onChange={updateSearch}
            startAdornment={
              <InputAdornment position={"start"}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <CapsulesList capsules={capsulesData} />
    </MainLayout>
  );
};

export default Home;
