import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import styles from "./styles.module.scss";
import CapsuleCard from "../../molecules/CapsuleCard";

const CapsulesList = ({ capsules = [] }) => {
  return (
    <Grid container component={"ul"} className={styles.root} spacing={7}>
      {capsules.map(({ token_id, image, title, isUnlocked, token_uri }) => (
        <CapsuleCard
          key={token_id}
          id={token_id}
          image={image}
          title={title}
          isUnlocked={isUnlocked}
          token_uri={token_uri}
        />
      ))}
    </Grid>
  );
};

CapsulesList.propTypes = {
  capsules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CapsulesList;
