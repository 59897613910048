import React from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { Button, Divider, Grid, Typography } from "@mui/material";
import styles from "./styles.module.scss";

import zodiacSymbols from "../../../constants/zodiacSymbols";
import ZodiacsList from "../ZodiacsList";
import ZodiacSymbols from "../ZodiacSymbols";
import CustomLabel from "../../atoms/CustomLabel";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: "rgba(0, 0, 0, 0.5)";
  -webkit-tap-highlight-color: transparent;
`;

const modalBox = {
  height: "90%",
  background: "rgba(0, 0, 0, 1)",
  border: "8px solid #FFFFFF",
  borderRadius: "32px",
  boxShadow: "0px 0px 64px #FF0073",
  p: 2,
  px: 0,
  pb: 3,
  pl: 5,
  pr: 5,
  m: 6,
};

const ownedZodiacsContainer = {
  overflow: "auto",
  maxHeight: "100%",
  pl: 4,
};

const contentContainer = {
  height: "75%",
  maxHeight: "100%",
};

const ownedContainer = {
  height: "90%",
  overflow: "auto",
  p: 2,
};

export default function ZodiacModal(props) {
  const handleClose = () => props.setOpen(false);

  // create a bool that is true if props.twelveZodiacs {variant: id} values are all not null
  const canUnlockAnimation = Object.values(props.twelveZodiacs).every(
    (zodiac) => zodiac !== null
  );

  return (
    <StyledModal
      aria-labelledby="zodiac-select-title"
      aria-describedby="zodiac-list"
      open={props.open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
    >
      <Grid
        container
        item
        xs={12}
        sx={modalBox}
        display="flex"
        key={props.twelveZodiacs}
      >
        <Grid item xs={12} className={styles.title}>
          <Typography variant="h3">Animated</Typography>
          <CustomLabel>
            Burn one of each Zodiac
            <br /> Click the Symbols on the left to filter
          </CustomLabel>
        </Grid>
        <Grid container item xs={12} sx={contentContainer}>
          <Grid
            container
            item
            xs={7}
            sx={{ borderRight: "4px solid #FFFFFF;" }}
          >
            <ZodiacSymbols
              key={props.filteredZodiac}
              zodiacSymbols={zodiacSymbols}
              setFilteredZodiac={props.setFilteredZodiac}
              filteredZodiac={props.filteredZodiac}
              twelveZodiacs={props.twelveZodiacs}
            />
          </Grid>

          <Grid container item xs={5} sx={ownedZodiacsContainer}>
            <Grid
              item
              display="flex"
              xs={12}
              sx={{ height: "10%" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" textAlign="center">
                Your Zodiacs
              </Typography>
            </Grid>
            <Grid item xs={12} sx={ownedContainer}>
              <ZodiacsList
                key={props.selectedZodiacId}
                zodiacs={props.zodiacs}
                setSelectedZodiacId={props.setSelectedZodiacId}
                selectedZodiacId={props.selectedZodiacId}
                filteredZodiac={props.filteredZodiac}
                setTwelveZodiacs={props.setTwelveZodiacs}
                twelveZodiacs={props.twelveZodiacs}
                allowTwelveZodiacs
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          {true && (
            <Button
              onClick={() => {
                handleClose();
                props.unlockAnimated();
              }}
              variant={"contained"}
            >
              Unlock Animated
            </Button>
          )}
        </Grid>
      </Grid>
    </StyledModal>
  );
}
