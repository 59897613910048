import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MainLayout from "../../components/templates/MainLayout";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import StatsParser from "../../components/molecules/StatsParser";
import FormUnlockCapsule from "../../components/molecules/FormUnlockCapsule";
import CapsulePic from "../../components/atoms/CapsulePic";
import CustomLabel from "../../components/atoms/CustomLabel";
import { ethers} from "ethers";
import axios from "axios";
import { MORALIS_KEY, CAPSULES_ADDRESS } from "../../config";
import GenericModal from "../../components/organisms/GenericModal";
import config from "../../config.json";
import PORTRAIT_ABI from "../../Portrait.json";
import ZODIAC_ABI from "../../Zodiac.json";
import Web3 from "web3";

import getTransactionReceiptMined from "../../getTransactionReceiptMined.js";

const web3 = new Web3(new Web3.providers.HttpProvider(config.ALCHEMY_API));

web3.eth.getTransactionReceiptMined = getTransactionReceiptMined;

const ViewCapsule = (props) => {
  const { id } = useParams();
  const [currentCapsule, setCurrentCapsule] = useState();
  const [isLoggedIntoMetamask, setIsLoggedIntoMetamask] = useState(false);
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState(null);
  const [signer, setSigner] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showApproval, setShowApproval] = useState(false);
  const [selectedZodiacId, setSelectedZodiacId] = useState(null);
  const [filteredZodiac, setFilteredZodiac] = useState(null);
  const [selectedZodiacList, setSelectedZodiacList] = useState([]);
  const [twelveZodiacs, setTwelveZodiacs] = useState({
    Aries: null,
    Virgo: null,
    Taurus: null,
    Libra: null,
    Leo: null,
    Cancer: null,
    Aquarius: null,
    Pisces: null,
    Sagittarius: null,
    Gemini: null,
    Capricorn: null,
    Scorpio: null,
  });

  const changeDisplayMode = async (displayMode) => {
    try {
      const signature = await signer.signMessage(address);

      setShowLoading(true);

      const response = await axios.post("/api/setDisplayMode", {
        address,
        signature,
        name: currentCapsule.title,
        displayMode,
      });

      await refreshData();
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };

  const changeBackground = async (background) => {
    try {
      const signature = await signer.signMessage(address);

      setShowLoading(true);

      const response = await axios.post("/api/setBackground", {
        address,
        signature,
        name: currentCapsule.title,
        background,
      });

      await refreshData();
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };

  const changeAnimated = async (animated) => {
    try {
      const signature = await signer.signMessage(address);

      setShowLoading(true);

      const response = await axios.post("/api/setAnimated", {
        address,
        signature,
        name: currentCapsule.title,
        animated,
      });

      await refreshData();
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };

  const unlockAdditionalBackgrounds = async () => {
    try {
      await setApprovalForAllIfNeeded();

      const contract = new ethers.Contract(
        config.PORTRAIT_ADDRESS,
        PORTRAIT_ABI.result
      );

      const params =
        await contract.populateTransaction.unlockSwappableBackgroundsWithZodiac(
          selectedZodiacId,
          parseInt(currentCapsule.title)
        );

      params.from = address;

      const gas = await provider.estimateGas(params);
      params.gas = ethers.utils.hexValue(gas);

      setShowLoading(true);

      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [params],
      });

      let receipt = await web3.eth.getTransactionReceiptMined(txHash);

      console.log(receipt);

      await refreshData();
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };

  const setApprovalForAllIfNeeded = async () => {
    try {
      const contract = new web3.eth.Contract(
        JSON.parse(ZODIAC_ABI.result),
        config.ZODIAC_ADDRESS
      );

      const isApprovedForAll = await contract.methods
        .isApprovedForAll(address, config.PORTRAIT_ADDRESS)
        .call();

      if (!isApprovedForAll) {
        const contract = new ethers.Contract(
          config.ZODIAC_ADDRESS,
          ZODIAC_ABI.result
        );

        const params = await contract.populateTransaction.setApprovalForAll(
          config.PORTRAIT_ADDRESS,
          true
        );

        params.from = address;

        const gas = await provider.estimateGas(params);
        params.gas = ethers.utils.hexValue(gas);

        setShowApproval(true);

        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [params],
        });

        const receipt = await web3.eth.getTransactionReceiptMined(txHash);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowApproval(false);
    }
  };

  const unlockAnimated = async () => {
    try {
      await setApprovalForAllIfNeeded();
      // Get all zodiac IDs from the dictionary twelveZodiacs if the value is not null
      const variants = Object.keys(twelveZodiacs).filter(
        (key) => twelveZodiacs[key] !== null
      );

      const zodiacIds = variants.map((variant) => twelveZodiacs[variant]);

      console.log(zodiacIds);
      const response = await axios.post(
        "/api/getRawTransactionForUnlockAnimatedWithZodiacs",
        {
          zodiacIds: zodiacIds,
          tokenId: parseInt(currentCapsule.title),
          address,
        }
      );

      const params = response.data;
      params.from = address;

      const gas = await provider.estimateGas(params);
      params.gas = ethers.utils.hexValue(gas);

      setShowLoading(true);

      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [params],
      });

      let receipt = await web3.eth.getTransactionReceiptMined(txHash);

      console.log(receipt);

      await refreshData();
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };

  const refreshData = async () => {
    try {
      if (typeof web3 !== "undefined") {
        console.log("web3 is enabled");
        await initWallet();
        if (web3.currentProvider.isMetaMask === true) {
          console.log("MetaMask is active");
        } else {
          console.log("MetaMask is not available");
        }
      } else {
        console.log("web3 is not found");
      }

      if (address) {
        const moralisKey = MORALIS_KEY;
        const capsuleResponse = await axios.get(
          `${CAPSULES_ADDRESS}${id}?chain=eth&format=decimal`,
          {
            headers: {
              "X-API-Key": moralisKey,
            },
          }
        );

        if (
          address.toString().toLowerCase() ===
          capsuleResponse.data.owner_of.toLowerCase()
        ) {
          setIsOwner(true);
        }

        const metadataResponse = await axios.get(`/api/metadata/${id}`);

        setCurrentCapsule({
          title: metadataResponse.data.name,
          image: {
            src: metadataResponse.data.image.replace(
              "gateway.pinata.cloud",
              "tunes.mypinata.cloud"
            ),
          },
          attributes: metadataResponse.data.attributes,
          hasMythic:
            metadataResponse.data.attributes.find(
              (obj) => obj.trait_type === "Mythic"
            ) !== undefined,
          additionalBackgrounds: true,
          animated: true,
          displayMode: metadataResponse.data.attributes.find(
            (obj) => obj.trait_type === "Display Mode"
          ).value,
          modifiable: {
            profilePic: {
              isUnlocked: true,
              label: "Display Mode",
              modifiableLabel: "Display Mode",
              value: metadataResponse.data.attributes.find(
                (obj) => obj.trait_type === "Display Mode"
              ).value,
            },
            background: {
              isUnlocked: true,
              label: "Background",
              modifiableLabel: "Background",
              value: metadataResponse.data.attributes.find(
                (obj) => obj.trait_type === "Portrait Background"
              ).value,
            },
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initWallet = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    setSigner(provider.getSigner(0));

    const accounts = await provider.listAccounts();

    window.ethereum.on("accountsChanged", function (accounts) {
      location.reload();
    });

    window.ethereum.on("chainChanged", (chainId) => {
      location.reload();
    });

    if (accounts.length > 0) {
      const address = await provider.getSigner(0).getAddress();

      setIsLoggedIntoMetamask(true);
      setProvider(provider);
      setAddress(address);
    }
  };

  useEffect(() => {
    refreshData();
  }, [address]);

  if (!currentCapsule) return null;

  const updateModifiable = (key, value) => {
    if (key === "profilePic") {
      changeDisplayMode(value.value);
    } else if (key === "background") {
      changeBackground(value.value);
    } else if (key === "animated") {
      changeAnimated(value.value);
    }
  };

  const unlockModifiable = (key) => {
    // setCurrentCapsule({
    //   ...currentCapsule,
    //   modifiable: {
    //     ...currentCapsule.modifiable,
    //     [key]: {
    //       ...currentCapsule.modifiable[key],
    //       isUnlocked: true,
    //     },
    //   },
    // });
  };

  const setTwelveZodiacsHelper = (variant, id) => {
    const updatedTwelveZodiacs = { ...twelveZodiacs };

    updatedTwelveZodiacs[variant] = id;
    setTwelveZodiacs(updatedTwelveZodiacs);
  };

  const { title, image, attributes, modifiable } = currentCapsule;

  console.log(currentCapsule);

  return (
    <MainLayout
      title="capsule details"
      button={{ label: address ? address.substring(0, 11) + "..." : "" }}
    >
      <GenericModal open={showLoading} />
      <GenericModal
        open={showApproval}
        message={
          "We need to do a one time transaction so our contracts can receive your Zodiacs."
        }
      />
      <Grid container spacing={7} pt={6}>
        <Grid item xs={12} sm={6} lg={4.82}>
          <CapsulePic
            hasMythic={currentCapsule.hasMythic}
            displayMode={currentCapsule.displayMode}
            xl
            alt={image.alt}
            src={image.src}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3.6}>
          <Typography mb={3} variant={"h4"}>
            {title}
          </Typography>
          <CustomLabel mb={2.5} label={"Attributes:"}>
            <StatsParser stats={attributes} />
          </CustomLabel>
        </Grid>
        {isOwner && (
          <Grid item xs={12} lg={3.55}>
            <FormUnlockCapsule
              attributes={attributes}
              modifiable={modifiable}
              updateModifiable={updateModifiable}
              unlock={unlockModifiable}
              isPortrait={
                currentCapsule.modifiable.profilePic.value === "Portrait" ||
                currentCapsule.modifiable.profilePic.value === "Portrait (Original)"
              }
              additionalBackgrounds={true}
              animated={true}
              hasMythic={currentCapsule.hasMythic}
              setSelectedZodiacId={setSelectedZodiacId}
              selectedZodiacId={selectedZodiacId}
              setFilteredZodiac={setFilteredZodiac}
              filteredZodiac={filteredZodiac}
              unlockBackgrounds={unlockAdditionalBackgrounds}
              setTwelveZodiacs={setTwelveZodiacsHelper}
              twelveZodiacs={twelveZodiacs}
              unlockAnimated={unlockAnimated}
            />
          </Grid>
        )}
      </Grid>
    </MainLayout>
  );
};

export default ViewCapsule;
