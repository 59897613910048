import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CustomSelect from "../../atoms/CustomSelect";

import { mockCapsuleOptions } from "../../../constants/mockCapsuleOptions";
import { Button, formControlLabelClasses } from "@mui/material";
import CustomLabel from "../../atoms/CustomLabel";
import ZodiacModal from "../ZodiacModal";
import ZodiacBackgroundModal from "../ZodiacBackgroundModal";
import { ethers, utils } from "ethers";
import axios from "axios";
import {
  MORALIS_KEY,
  BASE_ZODIAC_IMAGE_URI,
  BASE_MORALIS_URI,
  OWNED_ZODIACS_ADDRESS,
} from "../../../config";

const FormUnlockCapsule = ({
  attributes,
  modifiable,
  updateModifiable,
  unlock,
  defaultDisplayMode,
  defaultBackground,
  defaultAnimation,
  isPortrait,
  additionalBackgrounds,
  hasMythic,
  setSelectedZodiacId,
  setSelectedZodiacList,
  selectedZodiacId,
  setFilteredZodiac,
  filteredZodiac,
  unlockBackgrounds,
  setTwelveZodiacs,
  twelveZodiacs,
  unlockAnimated,
  animated,
}) => {
  // Fetch Zodiacs
  const [isLoggedIntoMetamask, setIsLoggedIntoMetamask] = useState(false);
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState(null);
  const [didLoad, setDidLoad] = React.useState(false);
  const [didFetch, setDidFetch] = useState(false);
  const [zodiacs, setZodiacs] = useState([]);

  const refreshData = async () => {
    try {
      if (typeof web3 !== "undefined") {
        console.log("web3 is enabled");
        await initWallet();
        if (web3.currentProvider.isMetaMask === true) {
          console.log("MetaMask is active");
        } else {
          console.log("MetaMask is not available");
        }
      } else {
        console.log("web3 is not found");
      }

      if (address) {
        const moralisKey = MORALIS_KEY;
        const ownedZodiacsResponse = await axios.get(
          `${BASE_MORALIS_URI}${address}${OWNED_ZODIACS_ADDRESS}`,
          {
            headers: {
              "X-API-Key": moralisKey,
            },
          }
        );

        console.log(`${BASE_MORALIS_URI}${address}${OWNED_ZODIACS_ADDRESS}`);

        const zodiacIds = ownedZodiacsResponse.data.result.map((zodiac) => {
          return zodiac.token_id;
        });

        const zodiacMetadata = await axios.get("/api/getZodiacMetadata", {
          params: {
            zodiacIds,
          },
        });

        const results = ownedZodiacsResponse.data.result.map((zodiac) => {
          zodiac.title = zodiac.token_id.padStart(5, "0");
          zodiac.image = {
            src: `${BASE_ZODIAC_IMAGE_URI}${zodiac.title}.png`,
          };

          zodiac.variant = zodiacMetadata.data.find((z) => {
            return z.name === zodiac.title;
          }).attributes[1].value;

          return zodiac;
        });

        setZodiacs(
          results.sort((a, b) =>
            parseInt(a.token_id) > parseInt(b.token_id) ? 1 : -1
          )
        );
      }

      setDidFetch(true);
    } catch (error) {
      console.log(error);
    }
  };

  const initWallet = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const accounts = await provider.listAccounts();

    window.ethereum.on("accountsChanged", function (accounts) {
      location.reload();
    });

    window.ethereum.on("chainChanged", (chainId) => {
      location.reload();
    });

    if (accounts.length > 0) {
      const address = await provider.getSigner(0).getAddress();

      setIsLoggedIntoMetamask(true);
      setProvider(provider);
      setAddress(address);
    }
  };

  useEffect(() => {
    refreshData();
  }, [address]);

  const zodiacsData = zodiacs;
  // End fetch Zodiacs

  const [state, setState] = useState({
    profilePic: defaultDisplayMode,
    background: defaultBackground,
    animated: defaultAnimation,
  });

  // Set background modal open/close. True = open
  const [bgModal, setBgModal] = useState({ open: false });
  const openBgModal = () => {
    // Await return and flip "Backgrounds" modifiable
    setBgModal({ open: true });
  };

  // Set animated modal open/close. True = open
  const [animatedModal, setAnimatedModal] = useState({ open: false });
  const openAnimatedModal = () => {
    // Await return and flip "Animated" modifiable
    setAnimatedModal({ open: true });
  };

  const onChange = (name, value) => {
    setState({ ...state, [name]: value });
    updateModifiable(name, value);
  };

  const isYokai =
    attributes.find((obj) => obj.trait_type === "Species").value === "Yokai"
      ? true
      : false;

  const isDemi =
    attributes.find((obj) => obj.trait_type === "Species").value ===
    "Demihumans"
      ? true
      : false;

  const isHuman =
    attributes.find((obj) => obj.trait_type === "Species").value === "Humans"
      ? true
      : false;

  const isPosthuman =
    attributes.find((obj) => obj.trait_type === "Species").value ===
    "Posthumans"
      ? true
      : false;

  let displayModeKey = "profilePic";

  let backgroundsKey = hasMythic
    ? "defaultBackgroundsMythic"
    : "defaultBackgrounds";

  if (additionalBackgrounds) {
    if (hasMythic) {
      backgroundsKey = "unlockedBackgroundsMythic";
    } else {
      backgroundsKey = "unlockedBackgrounds";
    }
  }

  return (
    <Grid container direction={"column"} pt={8.5}>
      {(isYokai || isHuman || isDemi || isPosthuman) &&
        Object.keys(modifiable).map((key, index) => {
          const item = modifiable[key];
          let options = mockCapsuleOptions[key];

          if (item.label === "Background") {
            options = mockCapsuleOptions[backgroundsKey];
          }

          if (item.label === "Display Mode") {
            options = mockCapsuleOptions[displayModeKey];
          }
          if ((item?.isUnlocked && isPortrait) || item.label === "Display Mode")
            return (
              <CustomLabel
                key={index + item.value}
                label={`${modifiable[key].label}:`}
              >
                <CustomSelect
                  key={index + item.value}
                  options={options}
                  label={`${item.label}:`}
                  value={{
                    value: item.value,
                    label: item.value,
                  }}
                  onChange={onChange}
                  name={key}
                />
              </CustomLabel>
            );
        })}

      {(isYokai || isHuman || isDemi || isPosthuman) && RenderZodiacOptions()}
    </Grid>
  );

  function RenderZodiacOptions() {
    return (
      <>
        {isPortrait && (
          <CustomLabel>
            {!additionalBackgrounds && (
              <Button onClick={openBgModal} fullWidth variant={"contained"}>
                Unlock More Backgrounds
              </Button>
            )}
          </CustomLabel>
        )}
        <CustomLabel>
          {!animated && (
            <Button onClick={openAnimatedModal} fullWidth variant={"contained"}>
              Unlock Animated
            </Button>
          )}

          <ZodiacModal
            open={animatedModal.open}
            setOpen={setAnimatedModal}
            zodiacs={zodiacsData}
            setSelectedZodiacId={setSelectedZodiacId}
            selectedZodiacId={selectedZodiacId}
            setFilteredZodiac={setFilteredZodiac}
            filteredZodiac={filteredZodiac}
            setTwelveZodiacs={setTwelveZodiacs}
            twelveZodiacs={twelveZodiacs}
            unlockAnimated={unlockAnimated}
          />

          <ZodiacBackgroundModal
            open={bgModal.open}
            setOpen={setBgModal}
            zodiacs={zodiacsData}
            setSelectedZodiacId={setSelectedZodiacId}
            selectedZodiacId={selectedZodiacId}
            unlockBackgrounds={unlockBackgrounds}
          />
        </CustomLabel>
      </>
    );
  }
};

export default FormUnlockCapsule;
