import Login from "../pages/Login";
import Home from "../pages/Home";
import ViewCapsule from "../pages/ViewCapsule";
import { Navigate } from "react-router-dom";

export const ROUTES = {
  Home: "/",
  ViewCapsule: "/view-capsule",
};

const routes = [
  {
    id: "signed_in",
    path: ROUTES.Home,
    element: <Home />,
    exact: false,
  },
  {
    id: "view-capsule",
    path: `${ROUTES.ViewCapsule}/:id`,
    element: <ViewCapsule />,
    exact: false,
  },
  {
    id: "redirect-to-login",
    path: "/",
    element: <Navigate to={"/"} />,
    exact: true,
  },
];

export default routes;
