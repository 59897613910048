import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import styles from "./styles.module.scss";
import ZodiacCard from "../../molecules/ZodiacCard";
import { Typography } from "@mui/material";

const ZodiacsList = ({
  zodiacs = [],
  setSelectedZodiacId,
  selectedZodiacId,
  filteredZodiac,
  setTwelveZodiacs,
  twelveZodiacs,
  allowTwelveZodiacs,
}) => {
  const twelveZodiacIDs = twelveZodiacs
    ? Object.keys(twelveZodiacs).map((key) => {
        return twelveZodiacs[key];
      })
    : [];

  if (filteredZodiac) {
    return (
      <Grid
        container
        component={"ul"}
        className={styles.root}
        columnSpacing={5}
        rowSpacing={4}
      >
        {zodiacs
          .filter((zodiac) => zodiac.variant.includes(filteredZodiac))
          .map(({ token_id, image, title, metadata, token_uri, variant }) => {
            return (
              <ZodiacCard
                key={token_id}
                id={token_id}
                image={image}
                title={title}
                metadata={metadata}
                token_uri={token_uri}
                variant={variant}
                isSelected={
                  allowTwelveZodiacs
                    ? twelveZodiacIDs.includes(token_id)
                    : selectedZodiacId === token_id
                }
                setSelectedZodiacId={setSelectedZodiacId}
                setTwelveZodiacs={setTwelveZodiacs}
                twelveZodiacs={twelveZodiacs}
              />
            );
          })}
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        component={"ul"}
        className={styles.root}
        columnSpacing={5}
        rowSpacing={4}
      >
        {zodiacs.map(
          ({ token_id, image, title, metadata, token_uri, variant }) => {
            return (
              <ZodiacCard
                key={token_id}
                id={token_id}
                image={image}
                title={title}
                metadata={metadata}
                token_uri={token_uri}
                variant={variant}
                isSelected={
                  allowTwelveZodiacs
                    ? twelveZodiacIDs.includes(token_id)
                    : selectedZodiacId === token_id
                }
                setSelectedZodiacId={setSelectedZodiacId}
                setTwelveZodiacs={setTwelveZodiacs}
                twelveZodiacs={twelveZodiacs}
              />
            );
          }
        )}
      </Grid>
    );
  }
};

ZodiacsList.propTypes = {
  zodiacs: PropTypes.arrayOf(
    PropTypes.shape({
      token_id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ZodiacsList;
