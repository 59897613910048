import React from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";

const CapsuleCard = ({ id, image, title, token_uri }) => {
  const navigate = useNavigate();

  const unlockHandling = () => {
    navigate(`${ROUTES.ViewCapsule}/${id}`, { id, image, title, token_uri });
  };

  const fileExt = image.src.split(".").pop();
  console.log(image.src);
  return (
    <Grid item xs={12} sm={6} md={4} xl={3} key={id} component={"li"}>
      <Box>
        <Grid container flexDirection="column">
          <Grid className={styles.card} item xs={12} mb={3}>
            {fileExt !== "mp4" && <img alt={image.alt} src={image.src} />}
            {fileExt === "mp4" && <video src={image.src} autoPlay loop muted />}
            <Grid className={styles.overlay}>
              <Button
                onClick={unlockHandling}
                size={"small"}
                variant="contained"
              >
                {"view\ncapsule"}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={styles.title}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

CapsuleCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default CapsuleCard;
