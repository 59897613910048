import React from "react";

import styles from "./styles.module.scss";

const CapsulePic = ({
  className,
  alt,
  xl,
  hasMythic,
  displayMode,
  animated,
  ...props
}) => {
  console.log(props);
  console.log(displayMode);
  if ((hasMythic && displayMode !== "Capsule") || displayMode === "Animated" || displayMode === "Animated (Original)") {
    return (
      <video
        className={[className, styles.root, xl && styles.xl].join(" ")}
        autoPlay
        loop
        muted
        controls
        {...props}
      />
    );
  }
  return (
    <img
      alt={alt}
      className={[className, styles.root, xl && styles.xl].join(" ")}
      {...props}
    />
  );
};

export default CapsulePic;
