const mockImage = require('../assets/images/example_capsulte.png')

const mockCapsules = [
  {
    id: '3139',
    title: '03139',
    image: {
      src: mockImage,
      alt: '03139 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    }
  },
  {
    id: '3140',
    title: '03140',
    image: {
      src: mockImage,
      alt: '03140 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    }
  },
  {
    id: '3141',
    title: '03141',
    image: {
      src: mockImage,
      alt: '03141 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    }
  },
  {
    id: '3142',
    title: '03142',
    image: {
      src: mockImage,
      alt: '03142 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    }
  },
  {
    id: '3143',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3153',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3163',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3173',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3183',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3193',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3243',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3343',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3443',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3543',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
  {
    id: '3643',
    title: '03143',
    image: {
      src: mockImage,
      alt: '03143 capsule image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Midnight Plum',
      purity: 'Augmented',
      decorative: 'Snoopy',
      mouth: 'Popsicle',
      species: 'Human',
      eyewear: 'Round Glasses',
      mythic: 'Halo',
      variant: 'Axe Warden',
      snoopy: 'Snoopy',
    },
    modifiable: {
      profilePic: {
        isUnlocked: true,
        label: 'Profile Pic',
        modifiableLabel: 'Profile Pic',
        value: 'Capsule',
      },
      background: {
        isUnlocked: false,
        label: 'Background',
        modifiableLabel: 'Backgrounds',
        value: null,
      },
      animated: {
        isUnlocked: false,
        label: 'Animated',
        modifiableLabel: 'Animated',
        value: null,
      },
    },
  },
]

export default  mockCapsules
