import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const palette = {
  primary: {
    main: '#ffffff'
  },
  secondary: {
    main: '#FF0073'
  },
  error: {
    main: red.A400
  },
}

export const reactSelectTheme = {
  colors: {
    primary: palette.secondary.main
  }
}

// A custom theme for this app
const theme = createTheme({
  palette: palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1730,
    }
  },
  typography: {
    fontFamily: '\'Press Start 2P\'',
    h2: {
      fontFamily: '\'04b\'',
      color: palette.primary.main,
      fontSize: '3rem',
      lineHeight: '3rem'
    },
    h4: {
      color: palette.primary.main
    },
    h3: {
      fontFamily: '\'04b\'',
      color: palette.primary.main
    },
    h5: {
      fontFamily: '\'04b\'',
      color: palette.primary.main,
      fontSize: '1.688rem',
    },
    h6: {
      fontSize: 24,
      color: palette.primary.main,
    },
    body1: {
      fontSize: 16,
      lineHeight: '20px',
      color: palette.primary.main,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: palette.secondary.main,
          color: palette.primary.main,
          fontSize: 12,
          fontWeight: '400',
          lineHeight: '1.3em',
          padding: '16px 20px',
          whiteSpace: 'break-spaces',
          textAlign: 'center',
          textTransform: 'none',
          borderRadius: 8,
          '&:hover': {
            backgroundColor: palette.secondary.main
          }
        },
        sizeLarge: {
          fontSize: '1.5rem',
          padding: '16px 24px',
          borderRadius: 16,
        },
        sizeMedium: {
          padding: '16px 20px'
        },
        sizeSmall: {
          padding: '8px 16px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '100%',
          color: palette.primary.main,
          fontSize: 12,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.main,
          }
        },
        notchedOutline: {
          borderColor: palette.primary.main,
          borderRadius: 8,
          borderWidth: 2,
        },
        input: {
          padding: '15.5px 13px',
          '&::placeholder': {
            opacity: 1,
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          margin: '0 3px 0 5px',
        }
      }
    }
  }
});

export default theme;
