const MORALIS_KEY =
  "ak4ClPYq259ou7IVWWx1OmFr5xDHrzWHk9A3cwgpM1gXB0TBjZRHN7s8ViUZGQ4y";
const BASE_IMAGE_URI =
  "https://tunes.mypinata.cloud/ipfs/QmWUcSn7pciRwsEnPd4whUzvbmbYRm4dVGaPn16X3D6dHF/";
const BASE_ZODIAC_IMAGE_URI =
  "https://tunes.mypinata.cloud/ipfs/QmXjL4fhy7F1hxGAeHKrwmnc4JefZkBDnWsZmBNb8bhqFZ/";
const BASE_MORALIS_URI = "https://deep-index.moralis.io/api/v2/";

// Because of dynamic parameter
const OWNED_CAPSULES_ADDRESS =
  "/nft/0xfcb1315c4273954f74cb16d5b663dbf479eec62e?chain=eth&format=decimal";
const OWNED_ZODIACS_ADDRESS =
  "/nft/0x35e1402fa69c60851ea8b86f04d823ff41796a51?chain=eth&format=decimal";

const CAPSULES_ADDRESS =
  "https://deep-index.moralis.io/api/v2/nft/0xfcb1315c4273954f74cb16d5b663dbf479eec62e/";

export {
  MORALIS_KEY,
  BASE_IMAGE_URI,
  BASE_ZODIAC_IMAGE_URI,
  BASE_MORALIS_URI,
  OWNED_CAPSULES_ADDRESS,
  OWNED_ZODIACS_ADDRESS,
  CAPSULES_ADDRESS,
};
