import React from 'react'
import Select, { components } from 'react-select'
import { ReactComponent as AngleDown } from '../../../assets/images/icons/angle-down.svg'

import { customStyles } from './styles'
import Grid from '@mui/material/Grid'

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <AngleDown/>
  </components.DropdownIndicator>
)

const CustomSelect = ({
  onChange,
  options,
  name,
  isSearchable = false,
  value,
  defaultValue,
  ...rest
}) => {
  const onSelectChange = (val) => {
    onChange(name, val)
  }

  return (
    <Grid {...rest}>
      <Select
        options={options}
        onChange={onSelectChange}
        components={{ DropdownIndicator }}
        styles={customStyles}
        value={value}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        menuPlacement={'auto'}
      />
    </Grid>
  )
}

export default CustomSelect
