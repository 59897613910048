const zodiacSymbols = [
  {
    value: "aries",
    label: "Aries",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/aries.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/aries.png")
        .default,
      alt: "aries zodiac symbol",
    },
  },
  {
    value: "virgo",
    label: "Virgo",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/virgo.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/virgo.png")
        .default,
      alt: "virgo zodiac symbol",
    },
  },
  {
    value: "taurus",
    label: "Taurus",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/taurus.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/taurus.png")
        .default,
      alt: "taurus zodiac symbol",
    },
  },
  {
    value: "libra",
    label: "Libra",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/libra.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/libra.png")
        .default,
      alt: "libra zodiac symbol",
    },
  },
  {
    value: "leo",
    label: "Leo",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/leo.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/leo.png").default,
      alt: "leo zodiac symbol",
    },
  },
  {
    value: "cancer",
    label: "Cancer",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/cancer.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/cancer.png")
        .default,
      alt: "cancer zodiac symbol",
    },
  },
  {
    value: "aquarius",
    label: "Aquarius",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/aquarius.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/aquarius.png")
        .default,
      alt: "aquarius zodiac symbol",
    },
  },
  {
    value: "pisces",
    label: "Pisces",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/pisces.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/pisces.png")
        .default,
      alt: "pisces zodiac symbol",
    },
  },
  {
    value: "sagittarius",
    label: "Sagittarius",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/sagittarius.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/sagittarius.png")
        .default,
      alt: "sagittarius zodiac symbol",
    },
  },
  {
    value: "gemini",
    label: "Gemini",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/gemini.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/gemini.png")
        .default,
      alt: "gemini zodiac symbol",
    },
  },
  {
    value: "capricorn",
    label: "Capricorn",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/capricorn.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/capricorn.png")
        .default,
      alt: "capricorn zodiac symbol",
    },
  },
  {
    value: "scorpio",
    label: "Scorpio",
    image: {
      standard: require("../assets/images/zodiacSigns/standard/scorpio.png")
        .default,
      glowing: require("../assets/images/zodiacSigns/glowing/scorpio.png")
        .default,
      alt: "scorpio zodiac symbol",
    },
  },
];

export default zodiacSymbols;
