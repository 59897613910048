const mockImage = require('../assets/images/example_zodiac.png')

const mockZodiacs = [
  {
    id: '0693',
    title: '00693',
    image: {
      src: mockImage,
      alt: '00693 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Mint Pistachio',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Aries'
    }
  },
  {
    id: '8029',
    title: '08029',
    image: {
      src: mockImage,
      alt: '08029 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Azure Sky',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Virgo'
    }
  },
  {
    id: '4352',
    title: '04352',
    image: {
      src: mockImage,
      alt: '04352 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Marmalade Apricot',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Taurus'
    }
  },
  {
    id: '3329',
    title: '03329',
    image: {
      src: mockImage,
      alt: '03329 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Deep Amethyst',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Leo'
    }
  },
  {
    id: '2875',
    title: '02875',
    image: {
      src: mockImage,
      alt: '02875 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Azure Sky',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Libra'
    }
  },
  {
    id: '4842',
    title: '04842',
    image: {
      src: mockImage,
      alt: '04842 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Concord Grape',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Sagittarius'
    }
  },
  {
    id: '9749',
    title: '09749',
    image: {
      src: mockImage,
      alt: '09749 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Scarlet Ruby',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Cancer'
    }
  },
  {
    id: '0787',
    title: '00787',
    image: {
      src: mockImage,
      alt: '00787 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Celestial Aqua',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Aquarius'
    }
  },
  {
    id: '9654',
    title: '09654',
    image: {
      src: mockImage,
      alt: '09654 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Mint Pistachio',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Capricorn'
    }
  },
  {
    id: '6224',
    title: '06224',
    image: {
      src: mockImage,
      alt: '06224 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'King Turquoise',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Pisces'
    }
  },
  {
    id: '2987',
    title: '02987',
    image: {
      src: mockImage,
      alt: '02987 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Lilac Lavender',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Gemini'
    }
  },
  {
    id: '7352',
    title: '07352',
    image: {
      src: mockImage,
      alt: '07352 zodiac image'
    },
    attributes: {
      generation: 'Alpha',
      chroma: 'Coral Peach',
      purity: 'Natural',
      species: 'Zodiac',
      variant: 'Scorpio'
    }
  },
]

export default mockZodiacs
