import { palette } from '../../../theme/theme'

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? palette.secondary.main : palette.primary.main,
    backgroundColor: state.isSelected ? 'rgba(255,255,255,.2)' : 'transparent',
    padding: 20,
    fontSize: 12,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,.5)'
    },
    '&:active': {
      backgroundColor: 'rgba(255,255,255,.7)'
    },
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 48,
    backgroundColor: 'transparent',
    border: '2px solid rgba(255, 255, 255, 0.9)',
    borderRadius: 8,
    '&:hover': {
      borderColor: 'rgba(255, 255, 255, 0.9)',
    }
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: palette.primary.main,
      fontSize: 12,
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 15,
  }),
  menu: (provided) => {
    return {
      ...provided,
      border: '2px solid #FFFFFF',
      backgroundColor: '#000',
      borderRadius: 8,
    }
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: 8,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'rgba(255,255,255, 0.5)' : 'rgba(255,255,255, 1)',
    '&:hover': {
      color: state.isFocused ? 'rgba(255,255,255, 0.3)' : 'rgba(255,255,255, .7)',
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 12,
  })
}
