import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import { ROUTES } from "../../routes/routes";
import AuthLayout from "../../components/templates/AuthLayout";

import styles from "./styles.module.scss";

const Login = ({ connectWallet }) => {
  const navigate = useNavigate();

  const connectHandling = () => {
    connectWallet();
  };

  return (
    <AuthLayout
      title="your capsules"
      button={{ label: "connect wallet", clickHandler: connectHandling }}
      mainStyles={styles.main}
    >
      <Grid
        container
        flexGrow={1}
        display="flex"
        alignItems="center"
        flexDirection={"column"}
      >
        <Grid item xs={12} mb={6}>
          <Typography variant="h6">
            Connect wallet to view your capsules
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant={"contained"}
            onClick={connectHandling}
            className={styles.buttonPrimaryGlow}
            size={"large"}
          >
            {"connect\nwallet"}
          </Button>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default Login;
